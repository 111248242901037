import React from "react";
import Helmet from "react-helmet";
import pagesMetaData from "../../pagesMeta";

interface Props {
  page: "home" | "pakketten" | "instructeurs" | "contact";
}

const Head: React.FC<Props> = ({ page }) => {
  const metadata = pagesMetaData[page];

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords.join(",")} />
      <meta name="og:title" content={metadata.title} />
      <meta name="og:description" content={metadata.description} />
      <meta name="og:site_name" content="Rijschool Lamar" />
      <meta name="og:type" content="website" />
      <meta
        name="og:image"
        content={process.env.GATSBY_PUBLIC_URL + metadata.thumbnail}
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default Head;
