type PageMeta = {
  title: string;
  description: string;
  keywords: string[];
  thumbnail: string;
};

interface PagesMeta {
  home: PageMeta;
  pakketten: PageMeta;
  motorpakketten: PageMeta;
  instructeurs: PageMeta;
  contact: PageMeta;
}

const baseKeyWords = [
  "rijschool lamar",
  "rijschool",
  "rijschool Leiden",
  "rijbewijs halen",
  "snel rijbewijs halen",
];

const pagesMeta: PagesMeta = {
  home: {
    title: "Rijschool Lamar Leiden",
    description:
      "Rijschool Lamar Leiden is de beste rijschool in Leiden en omstreken! Snel je rijbewijs halen in Leiden? Kom dan naar Rijschool Lamar!",
    keywords: baseKeyWords,
    thumbnail: "/images/homepage-thumbnail.png",
  },
  pakketten: {
    title: "Rijles Pakketten - Rijschool Lamar Leiden",
    description:
      "Rijles pakketten van rijschool Lamar. Wij hanteren voordelige rijles pakketten vanaf €950. Bekijk onze pakket pagina voor het rijlespakket wat het beste bij je past!",
    keywords: [
      ...baseKeyWords,
      "rijlespakketten",
      "voordelige rijles pakketten",
      "rijles pakketten",
    ],
    thumbnail: "/images/pakketten-thumbnail.png",
  },
  motorpakketten: {
    title: "Rijles Motor Pakketten - Rijschool Lamar Leiden",
    description:
      "Rijles motor pakketten van rijschool Lamar. Wij hanteren voordelige rijles pakketten vanaf €950. Bekijk onze motor pakket pagina voor het rijlespakket wat het beste bij je past!",
    keywords: [
      ...baseKeyWords,
      "rijlespakketten",
      "voordelige rijles pakketten",
      "rijles pakketten",
    ],
    thumbnail: "/images/pakketten-thumbnail.png",
  },
  instructeurs: {
    title: "Rijles instructeurs - Rijschool Lamar Leiden",
    description:
      "Onze rijles instructeurs van Rijschool Lamar in Leiden staan je met raad en daad bij om je rijbewijs zo snel mogelijk te halen! Lees meer over onze rijles instructeurs op deze pagina.",
    keywords: [...baseKeyWords, "rijles instructeurs", "Amar", "Rima"],
    thumbnail: "/images/instructeurs-thumbnail.jpg",
  },
  contact: {
    title: "Contact - Rijschool Lamar Leiden",
    description:
      "Neem contact op met Amar en plan een gratis proefles in bij Rijschool Lamar! Door Amar’s jarenlange ervaring als instructeur weet hij na een korte kennismaking precies welk pakket bij je past!",
    keywords: [...baseKeyWords, "contact"],
    thumbnail: "/images/homepage-thumbnail.png",
  },
};

export default pagesMeta;
